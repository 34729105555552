<template>
  <vs-card>
    <h3 class="mb-4">
      Nova Importação
    </h3>

    <div class="w-100 d-flex justify-content-between">
      <div class="content">
        <div class="select-group">
          <vs-select
            v-model="selectedSystemId"
            label="Sistema de Origem"
          >
            <vs-select-item
              v-for="system in systems"
              :key="system.systemId"
              :value="system.systemId"
              :text="system.systemDesc"
            />
          </vs-select>

          <vs-select
            v-model="selectedIntgType"
            label="Tipo da Integração"
          >
            <vs-select-item
              v-for="intgType in integrationTypes"
              :key="intgType.id"
              :value="intgType.typeId"
              :text="intgType.desctiption"
            />
          </vs-select>
        </div>

        <vs-textarea
          id="description-textarea"
          v-model="description"
          label="Descrição"
          placeholder="Descreva sua nova importação..."
          width="416px"
          height="100px"
          :counter-danger.sync="descriptionDanger"
          counter="255"
        />

        <div class="d-flex flex-column">
          <div class="d-flex align-items-start gap-3 mb-3">
            <label
              for="upload-file-input"
              class="mr-4"
            >
              <h6>Importe um arquivo do tipo .csv ou .json</h6>

              <small>Obs.: Os valores devem ser separados por ponto e vírgula.</small>
            </label>

            <vs-button
              color="primary"
              class="btnx"
              href.prevent
              icon="file_download"
              size="small"
            />

            <vs-dropdown>
              <vs-button
                class="btn-drop"
                type="filled"
                icon="expand_more"
                size="small"
              />

              <vs-dropdown-menu id="custom-dropdown-menu">
                <vs-dropdown-group vs-label="Exemplo CSV">
                  <vs-dropdown-item
                    v-for="exampleFile in exampleFiles"
                    :key="exampleFile.name"
                    class="p-0 b-0"
                  >
                    <vs-button
                      class="p-2 by-1 w-100 h-100 text-left"
                      :disabled="!exampleFile.url || exampleFile.url.length === 0"
                      type="flat"
                      @click="downloadFile(exampleFile.url)"
                    >
                      {{ exampleFile.name }}
                    </vs-button>
                  </vs-dropdown-item>
                </vs-dropdown-group>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <div class="d-flex align-items-center">
            <!-- Upload File Button -->
            <div>
              <input
                id="upload-file-input"
                class="file-input"
                type="file"
                accept=".csv, .json"
                @change="uploadFile"
              >

              <vs-button icon="file_upload">
                <label
                  class="m-0 cursor-pointer"
                  for="upload-file-input"
                >
                  Escolher...
                </label>
              </vs-button>
            </div>

            <p class="mb-0 ml-3">
              {{ fileName.length > 0 ? fileName : 'Nenhum arquivo selecionado.' }}
            </p>

            <vs-button
              v-if="fileName.length > 0"
              class="ml-1"
              color="dark"
              icon="close"
              type="flat"
              @click="clearFile"
            />
            <vs-button
              style="margin-left: 25px;"
              icon="upload_file"
              :disabled="submitDisabled"
              @click="submitFile"
            >
              Enviar Arquivo
            </vs-button>
          </div>
        </div>

        <hr class="custom-hr">

        <div
          class="d-flex flex-column"
        >
          <div
            style="height: 380px;"
          >
            <h4
              style=" padding: 0.5rem;"
            >
              Histórico de Importações
            </h4>
            <table class="table">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Descrição</th>
                  <th>Tipo</th>
                  <th>Linhas/Processado</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="importItem in paginatedImportList"
                  :key="importItem.fileName"
                >
                  <td>{{ formatImportDate(importItem.importDate) }}</td>
                  <td>{{ importItem.description }}</td>
                  <td>{{ importItem.importType }}</td>
                  <td>{{ `${importItem.totalLines}/${importItem.linesProcessed}` }}</td>
                  <td>{{ importItem.status }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div style="display: flex; justify-content: center;">
            <vs-pagination
              v-model="currentPage"
              :total="totalPages"
            />
          </div>
        </div>
      </div>
    </div>
  </vs-card>
</template>

<script>
/* Config */
import { configDomain } from '@/global';

/* Services */
import File from '@/services/file';
import System from '@/services/system';
import ImportService from '@/services/import';


const fileService = new File();
const systemService = new System();
const importService = new ImportService();

export default {
  name: 'NewImport',
  data: () => ({
    /* Data */
    systems: [],
    integrationTypes: [],
    importList: [],
    /* Controls */
    selectedSystemId: 0,
    selectedIntgType: 0,
    description: '',
    descriptionDanger: false,
    submitDisabled: true,
    currentPage: 1,
    itemsPerPage: 4,
    /* File */
    file: {},
    fileName: '',
  }),
  computed: {
    exampleFiles() {
      return [
        {
          name: 'App Oficial',
          url: `${configDomain}/sys/101/import-templates/app-oficial.csv`,
        },
        {
          name: 'Cadastros (com comentários)',
          url: `${configDomain}/sys/101/import-templates/cadastros.csv`,
        },
        {
          name: 'Eventos',
          url: `${configDomain}/sys/101/import-templates/eventos.csv`,
        },
        {
          name: 'Ingressos (com comentários)',
          url: `${configDomain}/sys/101/import-templates/ingressos.csv`,
        },
        {
          name: 'Lojas (com comentários)',
          url: `${configDomain}/sys/101/import-templates/lojas.csv`,
        },
        {
          name: 'OTT (com comentários)',
          url: `${configDomain}/sys/101/import-templates/ott.csv`,
        },
        {
          name: 'Planos de Assinatura',
          url: `${configDomain}/sys/101/import-templates/planos.csv`,
        },
        {
          name: 'Sócio Torcedor (com comentários)',
          url: `${configDomain}/sys/101/import-templates/socio-torcedor.csv`,
        },
      ];
    },
    paginatedImportList() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      if (this.importList) {
        return this.importList.slice(startIndex, endIndex);
      } else {
        return [];
      }
    },
    totalPages() {
      if (this.importList) {
        return Math.ceil(this.importList.length / this.itemsPerPage);
      } else {
        return 0;
      }
    },
    ownerId() {
      return this.$store.state.ownerID;
    },
  },
  created() {
    this.getIntegrationTypes();
    this.getSystems();
    this.getImportListByOwner();
  },
  updated() {
    this.setSubmitDisabled();
  },
  methods: {
    /* API */
    getImportListByOwner() {
      this.$store.dispatch('set_isLoadingActive', true);

      importService
        .getImportListByOwner(this.ownerId)
        .then((resp) => {
          this.importList = resp;
        })
        .catch((err) => alert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    formatImportDate(importDate) {
      // Converte a string de data para um objeto Date
      const dateObject = new Date(importDate);

      // Obtém as partes da data
      const year = dateObject.getFullYear();
      const month = `0${dateObject.getMonth() + 1}`.slice(-2);
      const day = `0${dateObject.getDate()}`.slice(-2);

      // Retorna a data formatada no formato desejado
      return `${year}-${month}-${day}`;
    },
    getIntegrationTypes() {
      fileService
        .getIntegrationTypes()
        .then(
          (resp) => {
            this.integrationTypes = resp;

            this.$store.dispatch('set_isLoadingActive', false);

            this.selectedIntgType = this.integrationTypes[0].typeId;
          },
          (err) => {
            alert(err);

            this.$store.dispatch('set_isLoadingActive', false);
          }
        );
    },
    getSystems() {
      this.$store.dispatch('set_isLoadingActive', true);

      systemService
        .getSystemsByOwner(this.ownerId)
        .then(
          (resp) => {
            this.systems = resp;

            this.$store.dispatch('set_isLoadingActive', false);

            this.selectedSystemId = this.systems[0].systemId;
          },
          (err) => {
            alert(err);

            this.$store.dispatch('set_isLoadingActive', false);
          }
        );
    },
    /* File */
    clearFile() {
      this.file = {};
      this.fileName = '';
    },
    uploadFile(evt) {
      this.file = evt.target.files[0];
      this.fileName = evt.target.files[0].name;

      this.fileUploadedAlert();
    },
    submitFile() {
      const files = [this.file];

      const importInfo = {
        systemId: this.selectedSystemId,
        fileType: this.selectedIntgType,
        description: this.description,
        ownerId: this.ownerId
      };

      this.$store.dispatch('set_isLoadingActive', true);

      fileService
        .import(
          files,
          importInfo,
        )
        .then(
          () => {
            this.fileSuccessAlert();

            this.resetInputs();

            this.$store.dispatch('set_isLoadingActive', false);
          },
          (err) => {
            this.$store.dispatch('set_isLoadingActive', false);

            alert(err);
          });
    },
    /* Helpers */
    downloadFile(url) {
      const link = document.createElement('a');
      link.href = url;
      link.download = true;
      link.click();
    },
    resetInputs() {
      this.clearFile();

      this.description = '';

      this.selectedIntgType = this.integrationTypes[0].typeId;

      this.selectedSystemId = this.systems[0].systemId;
    },
    setSubmitDisabled() {
      if (this.fileName.length < 1 || this.descriptionDanger) {
        this.submitDisabled = true;
      } else {
        this.submitDisabled = false;
      }
    },
    /* Alerts */
    fileUploadedAlert() {
      this.$vs.notify({
        title: 'Arquivo carregado',
        color: 'primary',
        text: `"${this.fileName}" carregado com sucesso.`,
      });
    },
    fileSuccessAlert() {
      this.$vs.notify({
        title: 'Arquivo enviado',
        color: 'success',
        text: `"${this.fileName}" enviado com sucesso.`,
      });
    },
  },
}
</script>

<style lang="scss" scoped>
/* Custom Buttons */
.btnx {
  border-radius: 5px 0px 0px 5px;
  margin-left: 10px !important;
}

.btn-drop {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0px 5px 5px 0px;
}

.content {
  display: flex;
  align-items: flex-start;
  flex-flow: column nowrap;
  gap: 2rem;

  & .select-group {
    align-items: flex-end;
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
  }

  & .custom-hr {
    height: .5px;
    width: 100%;
    margin: 0;
  }
}

.file-input {
  display: none;
}
</style>

<style>
/* Custom Menu */
#custom-dropdown-menu a.vs-dropdown--item-link {
  padding: 0 !important;
}
</style>
