import axios from 'axios';

const baseEnv = process.env.VUE_APP_BASEENV;
const baseDomain = process.env.VUE_APP_BASEDOMAIN;

const baseUrl = `${baseDomain}/${baseEnv}`;

export default class File {
  /* Files */
  getIntegrationTypes() {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/import/integrationTypes`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  import(files, importInfo) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/files/import`;

      const headers = { headers: { 'Content-Type': 'multipart/form-data' } };

      // Construct JSON content
      let formData = new FormData();

      // Append files
      files.forEach((file) => formData.append('Files', file));

      // Append files metadata
      formData.append('importInfo', JSON.stringify(importInfo));

      axios
        .post(
          url,
          formData,
          headers
        )
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  upload(files, fileType, ownerId) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/Files/${ownerId}/${fileType}`;

      const headers = { headers: { 'Content-Type': 'multipart/form-data' } };

      // Construct JSON content
      let formData = new FormData();

      // Append files
      files.forEach((file, index) => formData.append('Files[' + index + ']', file));

      axios
        .post(
          url,
          formData,
          headers
        )
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
